import { isEmpty } from "../utils/functionUtils";
import ActionTypes from "./actionTypes";
// import data from "../data/data.json";
import { getUniqueValuesFromArray, toPascalCase } from "../utils/functionUtils";
import resources from "../components/common/resources.json";
import config from "../config/config";

export const initialState = {
  projectData: {},
  nintyDaysData: {},
  clientData: [],
  clientList: [],
  storeList: [],
  selectedClient: "",
  selectedStore: "",
  selectedDashboard: "",
  selectedChannelLabel: "",
  selectedDate: "",
  selectedDateType: "",
  loading: false,
  userLoggedIn: false,
  authCodeSearch: "",
  userData: {},
  isApiError: false,
  projectDataError: undefined,
  refreshedOnTime: undefined,
  selectedTab: "MAP",
  loadingPer: false,
  fullscreenMode: false,
  selectedCategory: "",
  SiteMap: false,
  // isTradeDashboardOpen: true
  removedSubMatricsData: [],
  projectDataCheck: false,
  selectedAccountingMonth: "",
  reconciliationClient: "M1",
  reconciliationClientCelcom: "Celcom",
  selectedFileType: "",
  selectedRevenueClient:"",
  selectedRevenueMonth:"",
  selectedRevenueYear:"",
  uploadFileError: {},
  uploadedFileData: {},
  chooseFile: {},
  uploadFileDataClick: {},
  mapsPage: true,
  selectedReportFilter: 'real time',
  ssSelectedReportFilter: 'real time',
  hideDateFilter: false,
  email: "",
  password: "",
  slaReportCountSuccess: {},
  startDateToShow: "",
  endDateToShow: "",
  RoleData:[],
  reportType: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROJECT:
      return { ...state, projectData: action.payload };

    case ActionTypes.SELECTED_CLIENT:
      console.log({ action });
      let stores = [
        {
          name: "All",
          value: "all",
          dashboardId: "all",
          // label: state.clientData?.find((f) => f.ClientId === action.payload)?.Channels?.[0]?.Label,
          label: state?.uniqueClientsList?.find(
            (f) => f.ClientId === action.payload
          )?.Channels?.[0]?.Label,
        },
      ];

      // state.clientData
      state?.uniqueClientsList
        ?.find((f) => f.ClientId === action.payload)
        ?.Channels?.map((c) =>
          stores?.push({
            name: c.Channel,
            value: c.Channel,
            // dashboardId: c.DashboardId,
            AllDashboards: c.AllDashboards,
            label: c.Label,
          })
        );

      let checkStore = stores?.filter(
        (v) => v?.value?.toLowerCase() === state?.selectedStore?.toLowerCase()
      )?.length;

      let _isSGP = state?.clientData?.find((f) => f.ClientId === action.payload)?.Channels[0]?.AllDashboards?.length > 1 ? state?.clientData?.find((f) => f.ClientId === action.payload)?.Channels[0]?.AllDashboards?.filter(v => v?.Label === state?.selectedTab)[0]?.DashboardId : state?.clientData?.find((f) => f.ClientId === action.payload)?.Channels[0]?.AllDashboards?.[0]?.DashboardId
      let notSGP = !isEmpty(state?.favouriteData) ? state?.clientData?.find((f) => f.ClientId === action.payload)?.Channels[0]?.AllDashboards?.length > 1 ? state?.clientData?.find((f) => f.ClientId === action.payload)?.Channels[0]?.AllDashboards?.filter(v => v?.Label === state?.selectedTab)[0]?.DashboardId : state?.clientData?.find((f) => f.ClientId === action.payload)?.Channels[0]?.AllDashboards?.[0]?.DashboardId : state?.mapSelected?.toLowerCase() !== "australia" ? state?.allDashboardsList?.filter(v => v?.Label === state?.selectedTab)[0]?.DashboardId : state?.selectedDashboard || "all"
      //selectedDashboard: update logic if Client has multiple programs & dashboards
      return {
        ...state,
        selectedClient: action.payload,
        // selectedStore: state?.mapSelected?.toLowerCase() == "singapore" ? 'NA' : checkStore > 0 ? state?.selectedStore : "all",
        selectedStore: state?.mapSelected?.toLowerCase() != "australia" ? 'NA' : checkStore > 0 ? state?.selectedStore : "all",
        selectedDashboard: config.isSGP === 'true' ? _isSGP : notSGP,
        selectedChannelLabel: "",
        selectedDate: (state?.mapSelected?.toLowerCase() != "australia" && state?.selectedTab !== 'Home Plus BPM Performance' && state?.selectedDate === resources.SINCE_LAUNCH_VALUE) ? resources.LAST_90_DAYS_VALUE : state?.selectedDate || resources.LAST_90_DAYS_VALUE,
        // projectData: {},
        storeList: stores,
      };
    case ActionTypes.SELECTED_STORE:
      // const storeObject = state.clientData
      //   ?.find((f) => f.ClientId === state.selectedClient)
      //   ?.Channels?.find((s) => s.Channel === action.payload);

      let _storeList = state?.storeList?.filter(
        (s) => s.value?.toLowerCase() === action.payload?.toLowerCase()
      );
      let storeDashboard = _storeList?.[0]?.AllDashboards?.filter(
        (v) => state?.selectedTab == v.Label
      );
      return {
        ...state,
        selectedStore: action.payload,
        selectedDashboard: isEmpty(action.payload)
          ? ""
          : storeDashboard?.[0]?.DashboardId || "all",
        selectedChannelLabel: isEmpty(action.payload)
          ? ""
          : storeDashboard?.[0]?.Label || "all",
        selectedDate: state?.selectedDate || resources.LAST_90_DAYS_VALUE,
        // projectData: {},
        childSubmatricsData: undefined,
      };
    case ActionTypes.SELECTED_DASHBOARD_DATE:
      return {
        ...state,
        selectedDateType: action.payload.name,
        selectedDate: action.payload.value,
        // projectData: {},
      };

    // case ActionTypes.GET_CLIENTS_SUCCESS:
    //   return {
    //     ...state,
    //     clientData: action.payload,
    //     clientList: action.payload.map((c) => ({
    //       name: c.Client,
    //       value: c.ClientId,
    //     })),
    //   };

    case ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS:
      let uniqueClients = getUniqueValuesFromArray(
        action.payload?.GetRoleBasedMetricsResponse?.result,
        "ClientId"
      );
      let uniqueCountries = getUniqueValuesFromArray(
        action.payload?.GetRoleBasedMetricsResponse?.MasterData,
        "Country"
      );
      return {
        ...state,
        clientData: uniqueClients,
        countryData: uniqueCountries,
        clientList: action.payload?.GetRoleBasedMetricsResponse?.result?.map(
          (c) => ({
            name: toPascalCase(c?.Client?.replaceAll("_", " ")),
            value: c.ClientId,
          })
        ),
        userDetails: action.payload?.UserInfo,
        // userDetails: {firstName: 'Soumya', lastName: 'Nair'},
        FavouriteList:
          action.payload?.GetRoleBasedMetricsResponse?.FavouriteList,
        MasterData: action.payload?.GetRoleBasedMetricsResponse?.MasterData,
        clientsResultData: action.payload?.GetRoleBasedMetricsResponse?.result,
        notificationsData:
          action.payload?.GetRoleBasedMetricsResponse?.Notifications,
        RoleData: action.payload?.GetRoleBasedMetricsResponse?.Roles,
      };
    case ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE:
      return { ...state, projectDataError: action.payload };

    case ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS:
      return { ...state, projectData: action.payload, nintyDaysData: action?.payload?.Duration?.Duration === "LAST_90_DAYS" ?  action.payload : state.nintyDaysData };

    case ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS_90_DAYS:
      return { ...state, projectDataFor90Days: action.payload };

      

    case ActionTypes.GET_DASHBOARD_DETAILS_FAILURE:
      return { ...state, projectDataError: action.payload };

    case ActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };

    case ActionTypes.SET_LOADING_DATA:
      return { ...state, loadingData: action.payload };

    case ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS:
      return {
        ...state,
        submatricsDetailsData: action.payload,
      };
    case ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE:
      return { ...state, projectDataError: action.payload };

    case ActionTypes.SET_USER_LOGGED_IN:
      return { ...state, userLoggedIn: action.payload };
    case ActionTypes.SET_AUTH_CODE_SEARCH:
      return { ...state, authCodeSearch: action.payload };
    case ActionTypes.SET_USER_DATA:
      sessionStorage.setItem("token", action.payload.access_token);
      return { ...state, userData: action.payload };

    case ActionTypes.SET_USER_DATA_NEW:
      return { ...state, userDataNew: action.payload };

    case ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS:
      return {
        ...state,
        exportSubmatricsData: action.payload,
      };
    case ActionTypes.EXPORT_SUBMATRICS_DETAILS_FAILURE:
      return { ...state, projectDataError: action.payload };
    case ActionTypes.SET_REFRESHED_ON:
      return { ...state, refreshedOnTime: action.payload };

    case ActionTypes.SET_MAP_SELECTED:
      return { ...state, mapSelected: action.data };
    case ActionTypes.SET_PROGRAM_NAME:
      return { ...state, programName: action.data };

    case ActionTypes.SET_API_ERROR:
      return { ...state, ...{ isApiError: action.data } };  
   
    case ActionTypes.SET_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };
    case ActionTypes.SET_LOADING_PER:
      return { ...state, loadingPer: action.payload };
    case ActionTypes.SET_FULLSCREEN:
      return { ...state, fullscreenMode: action.payload };
    case ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS:
      return { ...state, childSubmatricsData: action.payload };
    case ActionTypes.GET_CHILD_SUBMATRICS_FAILURE:
      return { ...state, childSubmatricsError: action.payload };
    case ActionTypes.SET_DASHBOARD_LOADING:
      return { ...state, dashboardLoading: action.payload };
    case ActionTypes.SET_PROJECT_NAME:
      return { ...state, projectName: action.payload };

    case ActionTypes.SET_CATEGORY:
      return { ...state, selectedCategory: action.payload };

    case ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS:
      return { ...state, isTradeDashboardOpen: action.payload };

    case ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS:
      return { ...state, isTransactionOptionsOpen: action.payload };

    case ActionTypes.GET_TRANSACTION_REPORTS_SUCCESS:
      return { ...state, DataSourceList: action.payload };

    case ActionTypes.GET_TRANSACTION_REPORTS_FAILURE:
      return { ...state, projectDataError: action.payload };

    case ActionTypes.SET_COUNTRYDATA:
      return { ...state, country_Data: action.payload };

    case ActionTypes.SET_UNIQUE_CLIENTS:
      return { ...state, unique_Clients: action.payload };

    case ActionTypes.SET_PROGRAM_DATA:
      return { ...state, program_Data: action.payload };
    case ActionTypes.SET_UNIQUE_CLIENS_DATA:
      return {
        ...state,
        uniqueClientsList: action.payload,
        uniqueClientsData: action.payload?.map((c) => ({
          name: (c?.Client?.toUpperCase() === 'TRUE' || c?.Client?.toUpperCase() === 'AIS' || c?.Client?.toUpperCase() === 'FET') ? c?.Client : toPascalCase(c?.Client?.replaceAll("_", " ")),
          value: c.ClientId,
        })),
      };
    case ActionTypes.SET_ALL_DASHBOARDS:
      return { ...state, allDashboardsList: action.payload };

    case ActionTypes.SET_FAV_ARRAY:
      return { ...state, favArray: action.data };
    case ActionTypes.SET_FAV_ARRAY_CNT:
      return { ...state, favArryCnt: action.data };
    case ActionTypes.SET_SELECTED_FAV_DATA:
      return { ...state, favouriteData: action.data };
    case ActionTypes.SET_STORES_LIST:
      return { ...state, storeList: action.payload };
    case ActionTypes.SET_SITE_MAP:
      return { ...state, SiteMap: action.payload };
    case ActionTypes.SET_REMOVED_SUBMATRICS_DATA:
      return {
        ...state,
        ...{
          removedSubMatricsData: [
            ...state.removedSubMatricsData,
            action.payload,
          ],
        },
      };
    case ActionTypes.SET_UPDATED_SUBMATRICS_DATA:
      return { ...state, updatedSubmatricsData: action.payload };

    case ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS:
      return { ...state, projectDataCheck: action.payload };
    
      case ActionTypes.SELECTED_ACCOUNTING_MONTH:
        return {...state, selectedAccountingMonth: action.payload}
  
      case ActionTypes.SELECTED_REVENUE_CLIENT:
        return {...state, selectedRevenueClient: action.payload};

      case ActionTypes.SELECTED_REVENUE_MONTH:
        return {...state, selectedRevenueMonth: action.payload};
        
      case ActionTypes.SELECTED_REVENUE_YEAR:
        return {...state, selectedRevenueYear: action.payload};

      case ActionTypes.SELECTED_FILE_TYPE:
        return {...state, selectedFileType: action.payload};
  
      case ActionTypes.UPLOAD_FILE_ERROR: 
        return {...state, uploadFileError:Object.assign({}, {
          fileError: action.payload,
          uploadSuccess: false,
          isLoading: false,
          })}
      
       case ActionTypes.FILE_UPLOADER_SUCCESS:
        return {...state, uploadedFileData:Object.assign({}, {
          fileUploadedDetails: action.payload,
          isLoading: false,
          error: null,
          uploadSuccess: true
          })}
          
      case ActionTypes.SELECTED_FILE: 
      return {...state, chooseFile: action.payload}

      case ActionTypes.RESET_REVENUE_STATE: 
      return {...state, selectedRevenueClient: action.payload, 
        selectedRevenueMonth: action.payload, 
        selectedRevenueYear: action.payload, 
        selectedFileType:action.payload, chooseFile: action.payload}

      case ActionTypes.UPLOAD_FILE_CLICK: 
      return {...state, uploadFileDataClick:Object.assign({}, {
        fileType: action.payload.selectedFileType,
        accountingYear: action.payload.selectedAccountingYear,
        accountingMonth: action.payload.selectedAccountingMonth,
        client: action.payload.selectedAccountingClient
        })}

      case ActionTypes.SET_SELECTED_DASHBOARD: 
        return {...state, selectedDashboard: action.payload}
      case ActionTypes.GO_TO_MAPS_PAGE: 
        return {...state, mapsPage: action.payload}
      
      case ActionTypes.SGP_SET_REPORT_NAME: 
        return {...state, selectedStarhubReport: {
          starHubReportName: action.payload?.reportLabel || null,
          starHubReportValue: action.payload?.reportValue || null
        }}
      
      case ActionTypes.SGP_SET_REPORT_FILTER: 
        return {...state, selectedReportFilter: action.payload}

      case ActionTypes.SGP_SET_REPORT_FILTER_DURATION: 
        return {...state, reportFilterDuration: {
          type: action.payload.type,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          duration: action.payload.duration
        }}

      case ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS: 
        // console.log('action.payload?.GetReportDetails', action.payload?.GetReportDetails);
        return {...state, reportCountSuccess: action.payload?.GetReportDetails}
        
      case ActionTypes.GET_STARHUB_REPORT_COUNT_FAILURE: 
        return {...state, reportCountError: action.payload}

      case ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS: 
        return {...state, reportCountDetailsSuccess: action.payload?.GetReportCountDetailsResponse}
        
      case ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_FAILURE: 
        return {...state, reportCountDetailsError: action.payload}
        
      case ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS: 
        return {...state, reportDetailsSuccess: action.payload?.GetReportDetailsResponse}
        
      case ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE: 
        return {...state, reportDetailsError: action.payload}
        
      case ActionTypes.HIDE_DATE_FILTER: 
        return {...state, hideDateFilter: action.payload}

      case ActionTypes.SET_USER_DETAILS: 
        return {...state, userDetails: action.payload}
        
      case ActionTypes.GET_CHART_COUNT_DATA_SUCCESS:
        return {...state, chartReportCountSuccess: action.payload?.GetAllReportsCount}
        
      case ActionTypes.GET_CHART_COUNT_DATA_FAILURE: 
        return {...state, chartReportCountError: action.payload}

     case ActionTypes.SET_EMAIL_ADDRESS: 
        return {...state, email: action.payload}
 
    case ActionTypes.SET_PASSWORD:
      return {...state, password: action.payload}
      case ActionTypes.GET_SLA_REPORTS_DETAILS_SUCCESS:
        return {...state, slaReportCountSuccess: action.payload}
  
      case ActionTypes.GET_SLA_REPORTS_DETAILS_FAILURE:
        return { ...state, slaReportCountFailure: action.payload };
      case ActionTypes.SET_PROJECT_SUB_MENU: 
        return {...state, projectSHSubMenu: action.payload}

      case ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL: 
        return {...state, selectedDownloadReportDurationLBL: action.payload}
        
      case ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION: 
        return {...state, selectedDownloadReportDuration: action.payload}

      case ActionTypes.GET_SS_REPORT_COUNT_SUCCESS: 
        // console.log('action.payload?.GetSmartStockCountResponse', action.payload?.data?.GetSmartStockCountResponse);
        return {...state, ssreportCountSuccess: action.payload?.data?.GetSmartStockCountResponse}
        
      case ActionTypes.GET_SS_REPORT_COUNT_FAILURE: 
        return {...state, ssreportCountError: action.payload}

      case ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS: 
        // console.log('action.payload?.GetSSReportDetails', action.payload);
        return {...state, ssreportdetailsSuccess: action.payload?.GetSmartStocktDetailsResponse}
        
      case ActionTypes.GET_SS_REPORT_DETAILS_FAILURE: 
        return {...state, ssreportDetailsCountError: action.payload}

      case ActionTypes.SS_SET_REPORT_NAME: 
        return {...state, selectedSSReport: {
          ssReportName: action.payload?.reportLabel || null,
          ssReportValue: action.payload?.reportValue || null
        }}

      case ActionTypes.SS_SET_REPORT_FILTER: 
        return {...state, ssSelectedReportFilter: action.payload}

      case ActionTypes.SS_SET_REPORT_FILTER_DURATION: 
        return {...state, ssReportFilterDuration: {
          type: action.payload.type,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          duration: action.payload.duration
        }}

      case ActionTypes.SET_AI_REPORT_FILTER_DURATION: 
        return {...state, AIReportFilterDuration: {
          type: action.payload.type,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          duration: action.payload.duration
        }}

      case ActionTypes.SET_AI_REPORT_PROGRAM_TYPE: 
        return {...state, selectedAIReportProgramType: action.payload }

      case ActionTypes.SET_AI_REPORT_CLIENT_NAME: 
        return {...state, selectedAIReportClient: action.payload }

      case ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS: 
        return {...state, scorecardSummarySuccess: action.payload?.data?.ScoreCardSummaryResponse}
        
      case ActionTypes.GET_SCORECARD_SUMMARY_FAILURE: 
        return {...state, scoreCardSummaryFailure: action.payload}

      case ActionTypes.GET_PRM_EXPORT_DATA_SUCCESS: 
        return {...state, exportPrmSuccess: action.payload?.data?.GetPrmDataDetailsResponse}
        
      case ActionTypes.GET_PRM_EXPORT_DATA_FAILURE: 
        return {...state, exportPrmFailure: action.payload}

      case ActionTypes.GET_INQUIRY_DATA_SUCCESS: 
        return {...state, inquiryDataSuccess: action.payload?.data}
        
      case ActionTypes.GET_INQUIRY_DATA_FAILURE: 
        return {...state, inquiryDataFailure: action.payload}

      case ActionTypes.GET_EXPORT_INQUIRY_DATA_SUCCESS: 
        return {...state, exportInquiryDataSuccess: action.payload?.data}
        
      case ActionTypes.GET_EXPORT_INQUIRY_DATA_FAILURE: 
        return {...state, exportInquiryDataFailure: action.payload}

      case ActionTypes.SET_START_DATE:
        return { ...state, startDateToShow: action.payload };

      case ActionTypes.SET_END_DATE:
        return { ...state, endDateToShow: action.payload };

      case ActionTypes.GET_INQUIRY_AI_RESPONSE_SUCCESS: 
        return {...state, aiResponseInquirySuccess: action.payload?.data}
          
      case ActionTypes.GET_INQUIRY_AI_RESPONSE_FAILURE: 
        return {...state, aiResponseInquiryFailure: action.payload}

      case ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_SUCCESS: 
        return {...state, aiGraphResponseInquirySuccess: action.payload?.data}
          
      case ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_FAILURE: 
        return {...state, aiGraphResponseInquiryFailure: action.payload}

      case ActionTypes.VERIFY_JWT_SUCCESS: 
        sessionStorage.setItem('custom-token', action.payload.token)
        return {...state, verifyJwtSuccess: action.payload.token}
          
      case ActionTypes.VERIFY_JWT_FAILURE: 
        return {...state, verifyJwtFailure: action.payload}
    
      case ActionTypes.GET_CELCOM_BILLING_RECONCILE_DATA_SUCCESS: 
        return {...state, GetCelcomBillingReconcileSuccess: action.payload.data.GetCelcomBillingReconcile, GetCelcomBillingReconcileSuccessPass2: action.payload.data.GetCelcomBillingSecondPass, isPass2Inserted: action.payload.data.isPass2Inserted}
      
      case ActionTypes.GET_CELCOM_BILLING_RECONCILE_DATA_FAILURE: 
        return {...state, GetCelcomBillingReconcileFailure: action.payload}
        
      case ActionTypes.GET_AI_PERFORMANCE_REPORT_SUCCESS: 
        return {...state, GetAIPerformanceReportSuccess: action.payload}
      
      case ActionTypes.GET_AI_PERFORMANCE_REPORT_FAILURE: 
        return {...state, GetAIPerformanceReportFailure: action.payload}

      case ActionTypes.SET_SELECTED_REPORT_TYPE:
        return { ...state, reportType: action.payload };

      default:
      throw new Error();
  }
};

export default reducer;